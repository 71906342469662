import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout, getParentLayout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'
import { NO_RESET_WHITE_LIST } from '@/constants'

const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/self-evaluation/exam-online/list',
    name: 'Root',
    meta: {
      hidden: true
    }
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/personal',
    component: Layout,
    redirect: '/personal/personal-center',
    name: 'Personal',
    meta: {
      title: t('router.personal'),
      hidden: true,
      canTo: true
    },
    children: [
      {
        path: 'personal-center',
        component: () => import('@/views/Personal/PersonalCenter/PersonalCenter.vue'),
        name: 'PersonalCenter',
        meta: {
          title: t('router.personalCenter'),
          hidden: true,
          canTo: true
        }
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
  // {
  //   path: '/dashboard',
  //   component: Layout,
  //   redirect: '/dashboard/analysis',
  //   name: 'Dashboard',
  //   meta: {
  //     title: t('router.dashboard'),
  //     icon: 'vi-ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'analysis',
  //       component: () => import('@/views/Dashboard/Analysis.vue'),
  //       name: 'Analysis',
  //       meta: {
  //         title: t('router.analysis'),
  //         noCache: true,
  //         affix: true
  //       }
  //     },
  //     {
  //       path: 'workplace',
  //       component: () => import('@/views/Dashboard/Workplace.vue'),
  //       name: 'Workplace',
  //       meta: {
  //         title: t('router.workplace'),
  //         noCache: true
  //       }
  //     }
  //   ]
  // },
  {
    path: '/dictionary',
    component: Layout,
    redirect: '/dictionary/dictionaryList',
    name: 'Dictionary',
    meta: {
      title: '字典管理',
      icon: 'vi-ant-design:dashboard-filled',
      alwaysShow: true
    },
    children: [
      {
        path: 'dictionaryList',
        component: () => import('@/views/PvDictionaries/DictionaryList/DictionaryList.vue'),
        name: 'DictionaryList',
        meta: {
          title: '字典列表',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/self-evaluation',
    component: Layout,
    redirect: '/dictionary/dictionaryList',
    name: 'SelfEvaluation',
    meta: {
      title: '自评认证管理',
      icon: 'vi-ant-design:dashboard-filled'
    },
    children: [
      {
        path: 'statistics',
        component: () => import('@/views/PvSelfEvaluation/Statistics/Statistics.vue'),
        name: 'Statistics',
        meta: {
          title: '统计',
          noCache: true
        }
      },
      {
        path: 'question-bank',
        component: getParentLayout(),
        redirect: '/questionBank/list',
        name: 'QuestionBank',
        meta: {
          title: '试题库管理',
          alwaysShow: true
        },
        children: [
          {
            path: 'list',
            component: () => import('@/views/PvSelfEvaluation/QuestionBank/QuestionBankList.vue'),
            name: 'QuestionBankList',
            meta: {
              title: '试题库列表',
              noCache: true
            }
          },
          {
            path: 'single-choice',
            component: () => import('@/views/PvSelfEvaluation/QuestionBank/SingleChoice.vue'),
            name: 'single-choice',
            meta: {
              title: '单选题创编',
              noCache: true
            }
          },
          {
            path: 'multiple-choice',
            component: () => import('@/views/PvSelfEvaluation/QuestionBank/MultipleChoice.vue'),
            name: 'MultipleChoice',
            meta: {
              title: '多选题创编',
              noCache: true
            }
          }
        ]
      },
      // {
      //   path: 'question-examine',
      //   component: () => import('@/views/PvSelfEvaluation/QuestionExamine/QuestionExamine.vue'),
      //   name: 'QuestionExamine',
      //   meta: {
      //     title: '刷题系统管理',
      //     noCache: true
      //   }
      // },
      {
        path: 'exam-mock',
        component: getParentLayout(),
        name: 'ExamMock',
        meta: {
          title: '模拟考试系统管理',
          noCache: true
        },
        children: [
          {
            path: 'list',
            component: () => import('@/views/PvSelfEvaluation/ExamMock/ExamMockList.vue'),
            name: 'ExamMockList',
            meta: {
              title: '模考列表',
              noCache: true
            }
          },
          {
            path: 'create',
            component: () => import('@/views/PvSelfEvaluation/ExamMock/ExamMockCreate.vue'),
            name: 'ExamMockCreate',
            meta: {
              title: '模考创编',
              noCache: true
            }
          },
          {
            path: 'result-info',
            component: () => import('@/views/PvSelfEvaluation/ExamMock/ExamResutInfo.vue'),
            name: 'ExamMockResutInfo',
            meta: {
              title: '答卷信息',
              noCache: true,
              hidden: true,
              canTo: true,
              activeMenu: '/exam-mock/list'
            }
          },
          {
            path: 'result-detail',
            component: () => import('@/views/PvSelfEvaluation/ExamMock/ExamResutIDetail.vue'),
            name: 'ExamMockResutIDetail',
            meta: {
              title: '答卷详情',
              noCache: true,
              hidden: true,
              canTo: true,
              activeMenu: '/exam-mock/list'
            }
          },
          {
            path: 'result-static',
            component: () => import('@/views/PvSelfEvaluation/ExamMock/ExamResutStatic.vue'),
            name: 'ExamMockResutStatic',
            meta: {
              title: '答卷统计',
              noCache: true,
              hidden: true,
              canTo: true,
              activeMenu: '/exam-mock/list'
            }
          }
        ]
      },
      {
        path: 'exam-online',
        component: getParentLayout(),
        redirect: '/exam-online/list',
        name: 'ExamOnline',
        meta: {
          title: '在线考试系统管理',
          noCache: true
        },
        children: [
          {
            path: 'list',
            component: () => import('@/views/PvSelfEvaluation/ExamOnline/ExamOnlineList.vue'),
            name: 'ExamOnlineList',
            meta: {
              title: '考试列表',
              noCache: true
            }
          },
          {
            path: 'create',
            component: () => import('@/views/PvSelfEvaluation/ExamOnline/ExamOnlineCreate.vue'),
            name: 'ExamOnlineCreate',
            meta: {
              title: '考试创编',
              noCache: true
            }
          },
          {
            path: 'result-info',
            component: () => import('@/views/PvSelfEvaluation/ExamOnline/ExamResutInfo.vue'),
            name: 'ExamResutInfo',
            meta: {
              title: '答卷信息',
              noCache: true,
              hidden: true,
              canTo: true,
              activeMenu: '/exam-online/list'
            }
          },
          {
            path: 'result-detail',
            component: () => import('@/views/PvSelfEvaluation/ExamOnline/ExamResutIDetail.vue'),
            name: 'ExamResutIDetail',
            meta: {
              title: '答卷详情',
              noCache: true,
              hidden: true,
              canTo: true,
              activeMenu: '/exam-online/list'
            }
          },
          {
            path: 'result-static',
            component: () => import('@/views/PvSelfEvaluation/ExamOnline/ExamResutStatic.vue'),
            name: 'ExamResutStatic',
            meta: {
              title: '答卷统计',
              noCache: true,
              hidden: true,
              canTo: true,
              activeMenu: '/exam-online/list'
            }
          }
        ]
      },
      {
        path: 'question-examine',
        component: () => import('@/views/PvSelfEvaluation/QuestionExamine/QuestionExamine.vue'),
        name: 'QuestionExamine',
        meta: {
          title: '试题征集审核',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/expert',
    component: Layout,
    meta: {},
    name: 'Expert',
    children: [
      {
        path: 'list',
        component: () => import('@/views/PvExpert/PvExpertList.vue'),
        name: 'ExpertList',
        meta: {
          title: '专家信息管理',
          noCache: true,
          icon: 'vi-ant-design:dashboard-filled'
        }
      }
    ]
  },
  {
    path: '/banner',
    component: Layout,
    meta: {},
    name: 'Banner',
    children: [
      {
        path: 'list',
        component: () => import('@/views/PvBanner/BannerList.vue'),
        name: 'BannerList',
        meta: {
          title: '轮播图管理',
          noCache: true,
          icon: 'vi-ant-design:dashboard-filled'
        }
      }
    ]
  },
  {
    path: '/consumer',
    component: Layout,
    meta: {},
    name: 'Consumer',
    children: [
      {
        path: 'list',
        component: () => import('@/views/PvConsumer/ConsumerList.vue'),
        name: 'ConsumerList',
        meta: {
          title: '用户管理',
          noCache: true,
          icon: 'vi-ant-design:dashboard-filled'
        }
      }
    ]
  },
  {
    path: '/konwledge',
    component: Layout,
    meta: {},
    name: 'Konwledge',
    children: [
      {
        path: 'list',
        component: () => import('@/views/PvKnowledge/PvKnowledgeList.vue'),
        name: 'PvKnowledgeList',
        meta: {
          title: '知识库管理',
          noCache: true,
          icon: 'vi-ant-design:dashboard-filled'
        }
      }
    ]
  },
  {
    path: '/video',
    component: Layout,
    meta: {},
    name: 'Video',
    children: [
      {
        path: 'list',
        component: () => import('@/views/PvVideo/PvVideoList.vue'),
        name: 'PvVideoList',
        meta: {
          title: '视频管理',
          noCache: true,
          icon: 'vi-ant-design:dashboard-filled'
        }
      }
    ]
  },
  {
    path: '/suggest',
    component: Layout,
    meta: {},
    name: 'Suggest',
    children: [
      {
        path: 'list',
        component: () => import('@/views/PvSuggest/PvSuggestList.vue'),
        name: 'PvSuggestList',
        meta: {
          title: '反馈建议',
          noCache: true,
          icon: 'vi-ant-design:dashboard-filled'
        }
      }
    ]
  },
  {
    path: '/certificate',
    component: Layout,
    meta: {},
    name: 'Certificate',
    children: [
      {
        path: 'list',
        component: () => import('@/views/PvCertificate/PvCertificateList.vue'),
        name: 'PvCertificateList',
        meta: {
          title: '证书管理',
          noCache: true,
          icon: 'vi-ant-design:dashboard-filled'
        }
      }
    ]
  }

  // {
  //   path: '/external-link',
  //   component: Layout,
  //   meta: {},
  //   name: 'ExternalLink',
  //   children: [
  //     {
  //       path: 'https://element-plus-admin-doc.cn/',
  //       name: 'DocumentLink',
  //       meta: {
  //         title: t('router.document'),
  //         icon: 'vi-clarity:document-solid'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/guide',
  //   component: Layout,
  //   name: 'Guide',
  //   meta: {},
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/Guide/Guide.vue'),
  //       name: 'GuideDemo',
  //       meta: {
  //         title: t('router.guide'),
  //         icon: 'vi-cib:telegram-plane'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/components',
  //   component: Layout,
  //   name: 'ComponentsDemo',
  //   meta: {
  //     title: t('router.component'),
  //     icon: 'vi-bx:bxs-component',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'form',
  //       component: getParentLayout(),
  //       redirect: '/components/form/default-form',
  //       name: 'Form',
  //       meta: {
  //         title: t('router.form'),
  //         alwaysShow: true
  //       },
  //       children: [
  //         {
  //           path: 'default-form',
  //           component: () => import('@/views/Components/Form/DefaultForm.vue'),
  //           name: 'DefaultForm',
  //           meta: {
  //             title: t('router.defaultForm')
  //           }
  //         },
  //         {
  //           path: 'use-form',
  //           component: () => import('@/views/Components/Form/UseFormDemo.vue'),
  //           name: 'UseForm',
  //           meta: {
  //             title: 'UseForm'
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: 'table',
  //       component: getParentLayout(),
  //       redirect: '/components/table/default-table',
  //       name: 'TableDemo',
  //       meta: {
  //         title: t('router.table'),
  //         alwaysShow: true
  //       },
  //       children: [
  //         {
  //           path: 'default-table',
  //           component: () => import('@/views/Components/Table/DefaultTable.vue'),
  //           name: 'DefaultTable',
  //           meta: {
  //             title: t('router.defaultTable')
  //           }
  //         },
  //         {
  //           path: 'use-table',
  //           component: () => import('@/views/Components/Table/UseTableDemo.vue'),
  //           name: 'UseTable',
  //           meta: {
  //             title: 'UseTable'
  //           }
  //         },
  //         {
  //           path: 'tree-table',
  //           component: () => import('@/views/Components/Table/TreeTable.vue'),
  //           name: 'TreeTable',
  //           meta: {
  //             title: t('router.treeTable')
  //           }
  //         },
  //         {
  //           path: 'table-image-preview',
  //           component: () => import('@/views/Components/Table/TableImagePreview.vue'),
  //           name: 'TableImagePreview',
  //           meta: {
  //             title: t('router.PicturePreview')
  //           }
  //         },
  //         {
  //           path: 'table-video-preview',
  //           component: () => import('@/views/Components/Table/TableVideoPreview.vue'),
  //           name: 'TableVideoPreview',
  //           meta: {
  //             title: t('router.tableVideoPreview')
  //           }
  //         },
  //         {
  //           path: 'card-table',
  //           component: () => import('@/views/Components/Table/CardTable.vue'),
  //           name: 'CardTable',
  //           meta: {
  //             title: t('router.cardTable')
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: 'editor-demo',
  //       component: getParentLayout(),
  //       redirect: '/components/editor-demo/editor',
  //       name: 'EditorDemo',
  //       meta: {
  //         title: t('router.editor'),
  //         alwaysShow: true
  //       },
  //       children: [
  //         {
  //           path: 'editor',
  //           component: () => import('@/views/Components/Editor/Editor.vue'),
  //           name: 'Editor',
  //           meta: {
  //             title: t('router.richText')
  //           }
  //         },
  //         {
  //           path: 'json-editor',
  //           component: () => import('@/views/Components/Editor/JsonEditor.vue'),
  //           name: 'JsonEditor',
  //           meta: {
  //             title: t('router.jsonEditor')
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: 'search',
  //       component: () => import('@/views/Components/Search.vue'),
  //       name: 'Search',
  //       meta: {
  //         title: t('router.search')
  //       }
  //     },
  //     {
  //       path: 'descriptions',
  //       component: () => import('@/views/Components/Descriptions.vue'),
  //       name: 'Descriptions',
  //       meta: {
  //         title: t('router.descriptions')
  //       }
  //     },
  //     {
  //       path: 'image-viewer',
  //       component: () => import('@/views/Components/ImageViewer.vue'),
  //       name: 'ImageViewer',
  //       meta: {
  //         title: t('router.imageViewer')
  //       }
  //     },
  //     {
  //       path: 'dialog',
  //       component: () => import('@/views/Components/Dialog.vue'),
  //       name: 'Dialog',
  //       meta: {
  //         title: t('router.dialog')
  //       }
  //     },
  //     {
  //       path: 'icon',
  //       component: () => import('@/views/Components/Icon.vue'),
  //       name: 'Icon',
  //       meta: {
  //         title: t('router.icon')
  //       }
  //     },
  //     {
  //       path: 'icon-picker',
  //       component: () => import('@/views/Components/IconPicker.vue'),
  //       name: 'IconPicker',
  //       meta: {
  //         title: t('router.iconPicker')
  //       }
  //     },
  //     {
  //       path: 'echart',
  //       component: () => import('@/views/Components/Echart.vue'),
  //       name: 'Echart',
  //       meta: {
  //         title: t('router.echart')
  //       }
  //     },
  //     {
  //       path: 'count-to',
  //       component: () => import('@/views/Components/CountTo.vue'),
  //       name: 'CountTo',
  //       meta: {
  //         title: t('router.countTo')
  //       }
  //     },
  //     {
  //       path: 'qrcode',
  //       component: () => import('@/views/Components/Qrcode.vue'),
  //       name: 'Qrcode',
  //       meta: {
  //         title: t('router.qrcode')
  //       }
  //     },
  //     {
  //       path: 'highlight',
  //       component: () => import('@/views/Components/Highlight.vue'),
  //       name: 'Highlight',
  //       meta: {
  //         title: t('router.highlight')
  //       }
  //     },
  //     {
  //       path: 'infotip',
  //       component: () => import('@/views/Components/Infotip.vue'),
  //       name: 'Infotip',
  //       meta: {
  //         title: t('router.infotip')
  //       }
  //     },
  //     {
  //       path: 'input-password',
  //       component: () => import('@/views/Components/InputPassword.vue'),
  //       name: 'InputPassword',
  //       meta: {
  //         title: t('router.inputPassword')
  //       }
  //     },
  //     {
  //       path: 'waterfall',
  //       component: () => import('@/views/Components/Waterfall.vue'),
  //       name: 'waterfall',
  //       meta: {
  //         title: t('router.waterfall')
  //       }
  //     },
  //     {
  //       path: 'image-cropping',
  //       component: () => import('@/views/Components/ImageCropping.vue'),
  //       name: 'ImageCropping',
  //       meta: {
  //         title: t('router.imageCropping')
  //       }
  //     },
  //     {
  //       path: 'video-player',
  //       component: () => import('@/views/Components/VideoPlayer.vue'),
  //       name: 'VideoPlayer',
  //       meta: {
  //         title: t('router.videoPlayer')
  //       }
  //     },
  //     {
  //       path: 'avatars',
  //       component: () => import('@/views/Components/Avatars.vue'),
  //       name: 'Avatars',
  //       meta: {
  //         title: t('router.avatars')
  //       }
  //     },
  //     {
  //       path: 'i-agree',
  //       component: () => import('@/views/Components/IAgree.vue'),
  //       name: 'IAgree',
  //       meta: {
  //         title: t('router.iAgree')
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/function',
  //   component: Layout,
  //   redirect: '/function/multipleTabs',
  //   name: 'Function',
  //   meta: {
  //     title: t('router.function'),
  //     icon: 'vi-ri:function-fill',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'multiple-tabs',
  //       component: () => import('@/views/Function/MultipleTabs.vue'),
  //       name: 'MultipleTabs',
  //       meta: {
  //         title: t('router.multipleTabs')
  //       }
  //     },
  //     {
  //       path: 'multiple-tabs-demo/:id',
  //       component: () => import('@/views/Function/MultipleTabsDemo.vue'),
  //       name: 'MultipleTabsDemo',
  //       meta: {
  //         hidden: true,
  //         title: t('router.details'),
  //         canTo: true,
  //         activeMenu: '/function/multiple-tabs'
  //       }
  //     },
  //     {
  //       path: 'request',
  //       component: () => import('@/views/Function/Request.vue'),
  //       name: 'Request',
  //       meta: {
  //         title: t('router.request')
  //       }
  //     },
  //     {
  //       path: 'test',
  //       component: () => import('@/views/Function/Test.vue'),
  //       name: 'Test',
  //       meta: {
  //         title: t('router.permission'),
  //         permission: ['add', 'edit', 'delete']
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/hooks',
  //   component: Layout,
  //   redirect: '/hooks/useWatermark',
  //   name: 'Hooks',
  //   meta: {
  //     title: 'hooks',
  //     icon: 'vi-ic:outline-webhook',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'useWatermark',
  //       component: () => import('@/views/hooks/useWatermark.vue'),
  //       name: 'UseWatermark',
  //       meta: {
  //         title: 'useWatermark'
  //       }
  //     },
  //     {
  //       path: 'useTagsView',
  //       component: () => import('@/views/hooks/useTagsView.vue'),
  //       name: 'UseTagsView',
  //       meta: {
  //         title: 'useTagsView'
  //       }
  //     },
  //     {
  //       path: 'useValidator',
  //       component: () => import('@/views/hooks/useValidator.vue'),
  //       name: 'UseValidator',
  //       meta: {
  //         title: 'useValidator'
  //       }
  //     },
  //     {
  //       path: 'useCrudSchemas',
  //       component: () => import('@/views/hooks/useCrudSchemas.vue'),
  //       name: 'UseCrudSchemas',
  //       meta: {
  //         title: 'useCrudSchemas'
  //       }
  //     },
  //     {
  //       path: 'useClipboard',
  //       component: () => import('@/views/hooks/useClipboard.vue'),
  //       name: 'UseClipboard',
  //       meta: {
  //         title: 'useClipboard'
  //       }
  //     },
  //     {
  //       path: 'useNetwork',
  //       component: () => import('@/views/hooks/useNetwork.vue'),
  //       name: 'UseNetwork',
  //       meta: {
  //         title: 'useNetwork'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/level',
  //   component: Layout,
  //   redirect: '/level/menu1/menu1-1/menu1-1-1',
  //   name: 'Level',
  //   meta: {
  //     title: t('router.level'),
  //     icon: 'vi-carbon:skill-level-advanced'
  //   },
  //   children: [
  //     {
  //       path: 'menu1',
  //       name: 'Menu1',
  //       component: getParentLayout(),
  //       redirect: '/level/menu1/menu1-1/menu1-1-1',
  //       meta: {
  //         title: t('router.menu1')
  //       },
  //       children: [
  //         {
  //           path: 'menu1-1',
  //           name: 'Menu11',
  //           component: getParentLayout(),
  //           redirect: '/level/menu1/menu1-1/menu1-1-1',
  //           meta: {
  //             title: t('router.menu11'),
  //             alwaysShow: true
  //           },
  //           children: [
  //             {
  //               path: 'menu1-1-1',
  //               name: 'Menu111',
  //               component: () => import('@/views/Level/Menu111.vue'),
  //               meta: {
  //                 title: t('router.menu111')
  //               }
  //             }
  //           ]
  //         },
  //         {
  //           path: 'menu1-2',
  //           name: 'Menu12',
  //           component: () => import('@/views/Level/Menu12.vue'),
  //           meta: {
  //             title: t('router.menu12')
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: 'menu2',
  //       name: 'Menu2',
  //       component: () => import('@/views/Level/Menu2.vue'),
  //       meta: {
  //         title: t('router.menu2')
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/example',
  //   component: Layout,
  //   redirect: '/example/example-dialog',
  //   name: 'Example',
  //   meta: {
  //     title: t('router.example'),
  //     icon: 'vi-ep:management',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'example-dialog',
  //       component: () => import('@/views/Example/Dialog/ExampleDialog.vue'),
  //       name: 'ExampleDialog',
  //       meta: {
  //         title: t('router.exampleDialog')
  //       }
  //     },
  //     {
  //       path: 'example-page',
  //       component: () => import('@/views/Example/Page/ExamplePage.vue'),
  //       name: 'ExamplePage',
  //       meta: {
  //         title: t('router.examplePage')
  //       }
  //     },
  //     {
  //       path: 'example-add',
  //       component: () => import('@/views/Example/Page/ExampleAdd.vue'),
  //       name: 'ExampleAdd',
  //       meta: {
  //         title: t('router.exampleAdd'),
  //         noTagsView: true,
  //         noCache: true,
  //         hidden: true,
  //         canTo: true,
  //         activeMenu: '/example/example-page'
  //       }
  //     },
  //     {
  //       path: 'example-edit',
  //       component: () => import('@/views/Example/Page/ExampleEdit.vue'),
  //       name: 'ExampleEdit',
  //       meta: {
  //         title: t('router.exampleEdit'),
  //         noTagsView: true,
  //         noCache: true,
  //         hidden: true,
  //         canTo: true,
  //         activeMenu: '/example/example-page'
  //       }
  //     },
  //     {
  //       path: 'example-detail',
  //       component: () => import('@/views/Example/Page/ExampleDetail.vue'),
  //       name: 'ExampleDetail',
  //       meta: {
  //         title: t('router.exampleDetail'),
  //         noTagsView: true,
  //         noCache: true,
  //         hidden: true,
  //         canTo: true,
  //         activeMenu: '/example/example-page'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/error',
  //   component: Layout,
  //   redirect: '/error/404',
  //   name: 'Error',
  //   meta: {
  //     title: t('router.errorPage'),
  //     icon: 'vi-ci:error',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: '404-demo',
  //       component: () => import('@/views/Error/404.vue'),
  //       name: '404Demo',
  //       meta: {
  //         title: '404'
  //       }
  //     },
  //     {
  //       path: '403-demo',
  //       component: () => import('@/views/Error/403.vue'),
  //       name: '403Demo',
  //       meta: {
  //         title: '403'
  //       }
  //     },
  //     {
  //       path: '500-demo',
  //       component: () => import('@/views/Error/500.vue'),
  //       name: '500Demo',
  //       meta: {
  //         title: '500'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/authorization',
  //   component: Layout,
  //   redirect: '/authorization/user',
  //   name: 'Authorization',
  //   meta: {
  //     title: t('router.authorization'),
  //     icon: 'vi-eos-icons:role-binding',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'department',
  //       component: () => import('@/views/Authorization/Department/Department.vue'),
  //       name: 'Department',
  //       meta: {
  //         title: t('router.department')
  //       }
  //     },
  //     {
  //       path: 'user',
  //       component: () => import('@/views/Authorization/User/User.vue'),
  //       name: 'User',
  //       meta: {
  //         title: t('router.user')
  //       }
  //     },
  //     {
  //       path: 'menu',
  //       component: () => import('@/views/Authorization/Menu/Menu.vue'),
  //       name: 'Menu',
  //       meta: {
  //         title: t('router.menuManagement')
  //       }
  //     },
  //     {
  //       path: 'role',
  //       component: () => import('@/views/Authorization/Role/Role.vue'),
  //       name: 'Role',
  //       meta: {
  //         title: t('router.role')
  //       }
  //     }
  //   ]
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !NO_RESET_WHITE_LIST.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
